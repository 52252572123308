import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="maintenance-mode">
        {/* <h1>Coming Soon!</h1> */}
        <h2 className="margin-0">Guardians Of Platform!</h2>
        <h3> Building Trust in Software Together.</h3>

        <div className="cards">
          <div className="card">
            <img src="images/rpa-3.png" alt="build" />
            <span>Build</span>
          </div>
          <div className="card">
            <img src="images/rocket.png" alt="deploy" />
            <span>Deploy</span>
          </div>
          <div className="card">
            <img src="images/model.png" alt="operate" />
            <span>Operate</span>
          </div>
          <span>Trust</span>

        </div>
      </div>
      <div
        className="bg-img"
        style={{ backgroundImage: "url(images/thumbnail_image001.jpg)" }}
      />
    </div>
  );
}

export default App;
